import { signInWithEmailAndPassword, updatePassword, AuthErrorCodes } from "firebase/auth";
import { useCallback } from "react";

import useNotifyError from "hooks/api/useNotifyError";
import useClient from "hooks/useClient";
import useFirebaseContext from "hooks/useFirebaseContext";

const createErrorMessage = (code: string): string => {
  switch (code) {
    case AuthErrorCodes.INVALID_PASSWORD:
      return "現在のパスワードが違います";
    case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
      return "パスワードを連続で間違えたため、アカウントを一時的にロックしました。またあとでお試しください";
    default:
      return "一度ログアウトし、再度ログインしてからお試しください";
  }
};

const useChangePassword = (): {
  changePassword: (password: string, newPassword: string) => Promise<number | undefined>;
} => {
  const { client } = useClient();
  const { auth } = useFirebaseContext();
  const { notifyError } = useNotifyError();

  const changePassword = useCallback(
    async (password: string, newPassword: string) => {
      if (!auth.currentUser) return;
      // ログインし直す
      await signInWithEmailAndPassword(auth, auth.currentUser?.email ?? "", password).catch((e) => {
        notifyError({ reason: "signInWithEmailAndPassword", error: e, category: "firebase.auth" });
        throw createErrorMessage(e.code);
      });
      // パスワードを更新する
      await updatePassword(auth.currentUser, newPassword).catch((e) => {
        notifyError({ reason: "updatePassword", error: e, category: "firebase.auth" });
        throw createErrorMessage(e.code);
      });
      const { status } = await client({
        method: "post",
        url: "/password/changed",
      });
      return status;
    },
    [auth, client, notifyError]
  );
  return { changePassword };
};

export default useChangePassword;
