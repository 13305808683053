import { useCallback } from "react";

import useClient from "hooks/useClient";
import useUnauthorizedClient from "hooks/useUnauthorizedClient";

const useNotifyError = () => {
  const { unauthorizedClient } = useUnauthorizedClient();

  const notifyError = useCallback(
    async ({
      reason,
      error,
      category,
    }: {
      reason: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: any;
      category: string;
    }) => {
      const authUid = localStorage.getItem("uid") || undefined;
      const path = typeof window !== "undefined" ? window.location.pathname : "";
      const { status } = await unauthorizedClient({
        method: "post",
        url: "/notify-error",
        data: { reason, error, category, authUid, path },
      }).catch((e) => {
        return { status: 500 };
      });
      return status;
    },
    [unauthorizedClient]
  );

  return { notifyError };
};

export default useNotifyError;
