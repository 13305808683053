// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { getAuth, connectAuthEmulator, Auth } from "firebase/auth";
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { useEffect, useState } from "react";

import FirebaseContext from "contexts/FirebaseContext";

declare global {
  // eslint-disable-next-line no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);
const auth = getAuth(app);
const storage = getStorage(app);
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 600000;
remoteConfig.defaultConfig = {
  isMaintenanceMode: false,
  maintenanceDateString: "",
};
const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA;

if (process.env.REACT_APP_ENV === "local") {
  connectAuthEmulator(auth, "http://localhost:9099");
  remoteConfig.settings.minimumFetchIntervalMillis = 60000;

  connectStorageEmulator(storage, "127.0.0.1", 9199);

  self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_CHECK_DEBUG_TOKEN;
}

initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(RECAPTCHA_KEY as string),
  isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
});

const FirebaseProvider = ({ children }: { children: React.ReactNode }) => {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
  const [maintenanceDateString, setMaintenanceDateString] = useState("");

  useEffect(() => {
    (async () => {
      await fetchAndActivate(remoteConfig);
      setIsMaintenanceMode(getValue(remoteConfig, "isMaintenanceMode").asBoolean());
      setMaintenanceDateString(getValue(remoteConfig, "maintenanceDateString").asString());
    })();
  });

  const value = { analytics, auth, storage, config: { isMaintenanceMode, maintenanceDateString } };

  return <FirebaseContext.Provider value={value}>{children}</FirebaseContext.Provider>;
};

export default FirebaseProvider;
