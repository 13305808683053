import { Component, ReactNode, ErrorInfo } from "react";

import useNotifyError from "hooks/api/useNotifyError";

interface ErrorBoundaryProps {
  children: ReactNode;
  // エラー時に実行するコールバック関数。引数として error と errorInfo を受け取る。
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  notifyError?: ({ category, error, reason }: { reason: string; error: any; category: string }) => void;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true });

    // onError が指定されていれば実行
    this.props.notifyError &&
      this.props.notifyError({
        reason: "applicationCrash",
        error: {
          nativeError: {
            message: error.message,
            name: error.name,
            stack: error.stack,
          },
          errorLoc: errorInfo,
        },
        category: "applicationCrash",
      });
  }

  render() {
    if (this.state.hasError) {
      return <div>申し訳ありません。問題が発生しました。しばらくしてから再度お試しください。</div>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
