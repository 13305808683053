import { lazy, Suspense, useState } from "react";
import { useNavigate } from "react-router-dom";

import Loading from "components/Loading";
import useChangePassword from "hooks/api/useChangePassword";
import useNotifyError from "hooks/api/useNotifyError";
import { useInfoModal } from "hooks/useModal";
import useUserContext from "hooks/useUserContext";
import { isBrowser, isTablet } from "utils/deviceDetect";

const PasswordChangePresenter = lazy(() =>
  isBrowser
    ? import("pages/PasswordChange/pc/PasswordChangePresenter")
    : isTablet
      ? import("pages/PasswordChange/tb/PasswordChangePresenter")
      : import("pages/PasswordChange/sp/PasswordChangePresenter")
);

const PasswordChangeContainer = () => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const { user, setUser } = useUserContext();
  const { changePassword } = useChangePassword();
  const { InfoModal, showInfoModal, hideInfoModal } = useInfoModal();
  const { notifyError } = useNotifyError();

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);
  const onChangeNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value);
  const onChangeNewPasswordConfirm = (e: React.ChangeEvent<HTMLInputElement>) => setNewPasswordConfirm(e.target.value);

  const regexp = /.{6,}/;
  const isErrorPassword = !regexp.test(password);
  const isErrorNewPassword = !regexp.test(newPassword);
  const isErrorNewPasswordConfirm = newPassword !== newPasswordConfirm;
  const canSubmit = !isErrorPassword && !isErrorNewPassword && !isErrorNewPasswordConfirm;

  const onClose = () => {
    hideInfoModal();
    navigate("/");
  };

  const submit = async () => {
    const status = await changePassword(password, newPassword).catch((e) => {
      notifyError({ reason: "changePassword", error: e, category: "firebase.auth" });
      setErrorMessage(e);
    });
    if (status === 200) {
      setUser({ ...user, isRequiredChangePassword: false });
      setErrorMessage("");
      setNewPassword("");
      setNewPasswordConfirm("");
      showInfoModal();
    }
  };

  return (
    <Suspense fallback={<Loading />}>
      <PasswordChangePresenter
        password={password}
        newPassword={newPassword}
        newPasswordConfirm={newPasswordConfirm}
        errorMessage={errorMessage}
        onChangePassword={onChangePassword}
        onChangeNewPassword={onChangeNewPassword}
        onChangeNewPasswordConfirm={onChangeNewPasswordConfirm}
        isErrorPassword={isErrorPassword}
        isErrorNewPassword={isErrorNewPassword}
        isErrorNewPasswordConfirm={isErrorNewPasswordConfirm}
        canSubmit={canSubmit}
        onSend={submit}
        isRequiredChangePassword={user.isRequiredChangePassword}
        onClose={onClose}
        InfoModal={InfoModal}
      />
    </Suspense>
  );
};

export default PasswordChangeContainer;
