import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";

import ErrorBoundary from "class/ErrorBoundary";
import ErrorModal from "components/ErrorModal";
import FullSizeLoading from "components/FullSizeLoading";
import useGetUserMfa from "hooks/api/useGetUserMfa";
import useNotifyError from "hooks/api/useNotifyError";
import useErrorModalStatusContext from "hooks/useErrorModalStatusContext";
import useFirebaseContext from "hooks/useFirebaseContext";
import { useGoogleAnalytics } from "hooks/useGoogleAnalytics";
import useSharedModalContext from "hooks/useSharedModalContext";
import useUserContext from "hooks/useUserContext";
import Layout from "layouts/Layout";
import UnLoggedLayout from "layouts/UnLoggedLayout";
import AuthActionContainer from "pages/AuthAction/AuthActionContainer";
import BillingListController from "pages/Billing/BillingListController";
import Contract from "pages/Contract/ContractListContainer";
import EntryContainer from "pages/Entry/EntryContainer";
import LoginContainer from "pages/Login/LoginContainer";
import MaintenanceContainer from "pages/Maintenance/MaintenanceContainer";
import MfaEmailVerifyCompleteContainer from "pages/MfaEmailVerify/Complete/MfaEmailVerifyCompleteContainer";
import MfaEmailVerifyContainer from "pages/MfaEmailVerify/MfaEmailVerifyContainer";
import MfaEmailVerifySentContainer from "pages/MfaEmailVerify/Sent/MfaEmailVerifySentContainer";
import MfaResetContainer from "pages/MfaReset/MfaResetContainer";
import MfaRevertContainer from "pages/MfaRevert/MfaRevertContainer";
import MfaSettingContainer from "pages/MfaSetting/MfaSettingContainer";
import MfaStatusContainer from "pages/MfaStatus/MfaStatusContainer";
import OptionCreateContainer from "pages/OptionCreate/OptionCreateContainer";
import OptionListContainer from "pages/OptionList/OptionListContainer";
import OptionUpdateContainer from "pages/OptionUpdate/OptionUpdateContainer";
import OrderCreateContainer from "pages/OrderCreate/OrderCreateContainer";
import OrderCreateByUploadContainer from "pages/OrderCreateByUpload/OrderCreateByUploadContainer";
import OrderDetailContainer from "pages/OrderDetail/OrderDetailContainer";
import OrderListContainer from "pages/OrderList/OrderListContainer";
import OrderUpdate from "pages/OrderUpdate";
import PasswordChangeContainer from "pages/PasswordChange/PasswordChangeContainer";
import PasswordResetChangeContainer from "pages/PasswordReset/Change/PasswordResetChangeContainer";
import PasswordResetContainer from "pages/PasswordReset/PasswordResetContainer";
import ProgressContainer from "pages/Progress/ProgressContainer";
import UsageListContainer from "pages/Usage/UsageListContainer";
import UsageDetailContainer from "pages/UsageDetail/UsageDetailContainer";
import ErrorModalStatusProvider from "providers/ErrorModalStatusProvider";
import FirebaseProvider from "providers/FirebaseProvider";
import SharedModalProvider from "providers/SharedModalProvider";
import UserProvider from "providers/UserProvider";
import SharedModalRenderer from "templates/SharedModalRenderer";

const MfaGuardRoute = ({
  isRequiredMfa,
  MfaPage,
  Default,
}: {
  isRequiredMfa: boolean;
  MfaPage: JSX.Element;
  Default: JSX.Element;
}) => {
  const { userMfaInfo } = useGetUserMfa();
  const requiringMfa = isRequiredMfa && userMfaInfo != null && !userMfaInfo.isMfaEnabled;

  if (requiringMfa) return <>{MfaPage}</>;
  return <>{Default}</>;
};

// ログイン状態でルーティングを切り分ける
const PrivateRoute = () => {
  const { isUserLoaded } = useUserContext();
  const { auth } = useFirebaseContext();
  const { isVisible, httpMethod, onClose } = useErrorModalStatusContext();
  const { modalType: sharedModalType, onClose: sharedModalOnClose } = useSharedModalContext();
  const location = useLocation();
  useGoogleAnalytics();
  const { user } = useUserContext();

  // ユーザ情報の読込が終わっていない
  // isUserLoadedは読み込み時必ずfalse
  // localStorageからFirebase Authenticationのログイン情報を読み込んだらtrueになる
  if (!isUserLoaded) return <FullSizeLoading />;
  // 非ログイン状態
  if (!auth.currentUser) return <Navigate to="login" replace state={{ from: location.pathname + location.search }} />;

  return (
    <MfaGuardRoute
      isRequiredMfa={user.isRequiredMfa}
      Default={
        <Layout>
          <Outlet />
          <ErrorModal isVisible={isVisible} onClose={onClose} httpMethod={httpMethod} />
          <SharedModalRenderer modalType={sharedModalType} onClose={sharedModalOnClose} />
        </Layout>
      }
      MfaPage={
        <UnLoggedLayout>
          <>
            <Outlet />
            <ErrorModal isVisible={isVisible} onClose={onClose} httpMethod={httpMethod} />
            <SharedModalRenderer modalType={sharedModalType} onClose={sharedModalOnClose} />
          </>
        </UnLoggedLayout>
      }
    />
  );
};

// パスワード変更要求フラグでルーティングを切り分ける
const SecureRoute = () => {
  const { user } = useUserContext();
  // パスワード変更を強制する
  if (user.isRequiredChangePassword) return <Navigate to="password" replace />;
  // 通常
  return <Outlet />;
};

// MFA設定が必要のユーザーに対応するためのルーティング
const VerifiedUserRoute = () => {
  const { user } = useUserContext();
  const { userMfaInfo } = useGetUserMfa();

  if (user.isRequiredMfa && userMfaInfo != null && !userMfaInfo.isMfaEnabled) {
    return <Navigate to="mfa-setting" replace />;
  }

  return <Outlet />;
};

// loginはヘッダを表示しないため、"/"のRouteの外に出している
const AppRouting = () => {
  // メンテナンス中かどうか
  const { config } = useFirebaseContext();
  if (config.isMaintenanceMode) {
    return <MaintenanceContainer maintenanceDateString={config.maintenanceDateString} />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="auth-action" element={<AuthActionContainer />} />
        <Route path="login" element={<LoginContainer />} />
        <Route path="entry" element={<EntryContainer />} />
        <Route path="password-reset" element={<PasswordResetContainer />} />
        <Route path="password-reset/change" element={<PasswordResetChangeContainer />} />
        <Route path="mfa-reset" element={<MfaResetContainer />} />
        <Route path="mfa/revert" element={<MfaRevertContainer />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="password" element={<PasswordChangeContainer />} />
          <Route path="" element={<SecureRoute />}>
            <Route path="" element={<VerifiedUserRoute />}>
              <Route index element={<ProgressContainer />} />
              <Route path="order">
                <Route path="" element={<OrderListContainer />} />
                <Route path="create" element={<OrderCreateContainer />} />
                <Route path="create-by-file" element={<OrderCreateByUploadContainer />} />
                <Route path=":id">
                  <Route path="" element={<OrderDetailContainer />} />
                  <Route path="contract" element={<Contract />} />
                  <Route path="update" element={<OrderUpdate />} />
                </Route>
              </Route>
              <Route path="option">
                <Route path="" element={<OptionListContainer />} />
                <Route path="create" element={<OptionCreateContainer />} />
                <Route path=":id">
                  <Route path="update" element={<OptionUpdateContainer />} />
                </Route>
              </Route>
              <Route path="billing" element={<BillingListController />} />
              <Route path="usage" element={<UsageListContainer />}></Route>
              <Route path="usage/:id" element={<UsageDetailContainer />} />
            </Route>
            <Route path="mfa">
              <Route path="" element={<MfaStatusContainer />} />
              <Route path="email-verify" element={<MfaEmailVerifyContainer />}></Route>
              <Route path="email-verify-sent" element={<MfaEmailVerifySentContainer />} />
              <Route path="email-verify-complete" element={<MfaEmailVerifyCompleteContainer />} />
            </Route>
            <Route path="mfa-setting" element={<MfaSettingContainer />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

// Context.Providerで囲んで子要素がContextを使えるようにする
const App = () => {
  const { notifyError } = useNotifyError();
  return (
    <ErrorBoundary notifyError={notifyError}>
      <FirebaseProvider>
        <ErrorModalStatusProvider>
          <SharedModalProvider>
            <UserProvider>
              <AppRouting />
            </UserProvider>
          </SharedModalProvider>
        </ErrorModalStatusProvider>
      </FirebaseProvider>
    </ErrorBoundary>
  );
};

export default App;
